import { useEffect, useState } from "react";

export type ThemeMode = "light" | "dark";

export function useThemeMode(): [ThemeMode, (mode: ThemeMode) => void] {
  const [themeMode, setThemeMode] = useState<ThemeMode>("light");

  const handleChange = (event: MediaQueryListEvent) => {
    setThemeMode(event.matches ? "dark" : "light");
  };

  useEffect(() => {
    const preferredColor = window.matchMedia("(prefers-color-scheme: dark)");

    setThemeMode(preferredColor.matches ? "dark" : "light");

    preferredColor.addEventListener("change", handleChange);

    return () => {
      preferredColor.removeEventListener("change", handleChange);
    };
  }, []);

  return [themeMode, setThemeMode];
}
